import React from 'react';
import {
  Widget, 
  AboutWidget, 
  RecentPostWidget, 
  GalleryWidget,
  IconLink,
  Logo
} from '../../components/';

// Widgets Data
import widgetsData from '../../data/widgets.json';
const {recentPosts, galleryImages} = widgetsData;


const SidebarPopup = ({className, show, onHide})=> (
  <div className= {`sidemenu-wrapper ${className || ''} ${show ? 'show' : ''}`} >
    <div className="sidemenu-content">
      <button className="closeButton sideMenuCls" onClick={onHide} ><i className="far fa-times"/></button>
      <Widget>
        <AboutWidget>
          <Logo image="images/full_logo.png" className="footer-logo"/>
          <AboutWidget.Text>One-stop Innovation Lab to Ideate, Innovate, Craft, and Build Digital Data Strategy to pivot your business horizon using Digital Technologies.</AboutWidget.Text>
          <IconLink className="footer-social">
            <span className='icon-link'>
              <a href='https://medium.com/@DatumCreators' target='_blank' rel='noreferrer'>
                <i className='fab fa-medium'></i>
              </a>
            </span>
            <span className='icon-link'>
              <a href='https://x.com/datumcreators?s=21' target='_blank' rel='noreferrer'><i className='fab fa-twitter'></i></a>
            </span>
            <span className='icon-link'>
              <a href='https://www.linkedin.com/company/datum-ai-labs/about/' target='_blank' rel='noreferrer'><i className="fab fa-linkedin"></i></a>
            </span>
            <span className='icon-link'>
              <a href='https://www.youtube.com/@DatumAILabs' target='_blank' rel='noreferrer'><i className="fab fa-youtube"></i></a>
            </span>
          </IconLink>
        </AboutWidget>
      </Widget>        
      {/* <Widget widgetTitle="Gallery Posts">
        <GalleryWidget>
          {galleryImages.map((img, index) => (          
            <GalleryWidget.Item key={index} src={img} alt="thumb"/>
          ))}
        </GalleryWidget>
      </Widget>     
      <Widget widgetTitle="Recent Posts">
        {recentPosts.slice(0, 2).map(post => (
          <RecentPostWidget key={post.id}
            title={post.title}
            image={post.image}
            date={post.date}
            path={post.path}
          />
        ))}
      </Widget>         */}
    </div>
  </div>
);



export default SidebarPopup;