import React from 'react';
import { Link } from 'react-router-dom';
import {
  InfoMedia,
  Widget,
  AboutWidget,
  CategoryWidget,
  RecentPostWidget,
  IconLink
} from '../../components/';

// Widget Datas
import widgetsdata from '../../data/widgets.json';

const { pageList, exploreLinks, recentPosts } = widgetsdata;


const FooterOne = () => (
  <footer className="footer-wrapper footer-layout1 background-image" style={{ backgroundImage: 'url(images/shape/bg-footer-1-1.jpg)' }}>
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="footer-media col-12">
            <InfoMedia className="col-12 col-md-4 col-lg-3"
              title="UK Office Address"
              addr1="123, Coteford Street,"
              addr2="Tooting, London SW17 8NX"
              country="UK"
              number="+44 7908771454"
            />
            <InfoMedia className="col-12 col-md-4 col-lg-3 col-xl-2"
              title="US Office Address"
              addr1="751, Dixon Street,"
              addr2="Centerton 72719 Arkansas,"
              country="USA"
              number="+1 (408) 393-2054"
            />
            <InfoMedia className="col-12 col-md-4 col-lg-3"
              title="India Office Address"
              addr1="F2C, Tranquil Acres Phase II, 200Ft MMRD Road,"
              addr2="Kovilambakkam, Chennai 600117"
              country="India"
              number="+91 9841162317"
            />
            <InfoMedia
              title="Contact Us"
              className="col-12 col-md-4 col-lg-3"
              info={<>
                <a href="mailto:creators@datumailabs.com">creators@datumailabs.com</a>
              </>}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="widget-area">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-6 col-xl-auto px-xl-0">
            <Widget widgetTitle="About Us" className="footer-widget">
              <AboutWidget>
                <AboutWidget.Text>One-stop Innovation Lab to Ideate, Innovate, Craft, and Build Digital Data Strategy to pivot your business horizon using Digital Technologies.</AboutWidget.Text>
                <IconLink className="footer-social">
                  <span className='icon-link'>
                    <a href='https://medium.com/@DatumCreators' target='_blank' rel='noreferrer'>
                      <i className='fab fa-medium'></i>
                    </a>
                  </span>
                  <span className='icon-link'>
                    <a href='https://x.com/datumcreators?s=21' target='_blank' rel='noreferrer'><i className='fab fa-twitter'></i></a>
                  </span>
                  <span className='icon-link'>
                    <a href='https://www.linkedin.com/company/datum-ai-labs/about/' target='_blank' rel='noreferrer'><i className="fab fa-linkedin"></i></a>
                  </span>
                  <span className='icon-link'>
                    <a href='https://www.youtube.com/@DatumAILabs' target='_blank' rel='noreferrer'><i className="fab fa-youtube"></i></a>
                  </span>
                </IconLink>
              </AboutWidget>
            </Widget>
          </div>
          <div className="col-6 col-xl-auto">
            <Widget widgetTitle="Quick Links" className="widget_nav_menu footer-widget">
              <CategoryWidget>
                {pageList.map(cat => (
                  cat.visible === "Y" && <CategoryWidget.Item key={cat.name} path={cat.path}>{cat.name}</CategoryWidget.Item>
                ))}
              </CategoryWidget>
            </Widget>
          </div>
        </div>
      </div>
    </div>
    <div className="copyright-wrap">
      <div className="container">
        <p className="copyright-text">Copyright <i className="fal fa-copyright" /> 2023 <Link className="text-white" to="/about">Datum AI Labs</Link></p>
      </div>
    </div>
  </footer>
);

export default FooterOne;